import { usePostTrackEventWithParams } from "./analyticsQueries"

type TCallAssistEventTypes =
  | "Call Assist Activation Initiated"
  | "Call Assist Activation Confirmed"
  | "Call Assist Toggled"
  | "Call Assist Cancel Initiated"
  | "Call Assist Cancel Confirmed"

function usePostCallAssistTrackingEvent<TEventProperties = void>(
  event: TCallAssistEventTypes
) {
  return usePostTrackEventWithParams<TCallAssistEventTypes, TEventProperties>({
    event,
  })
}

export function usePostCallAssistActivationInitiated() {
  return usePostCallAssistTrackingEvent<void>(
    "Call Assist Activation Initiated"
  )
}

export function usePostCallAssistAcitvationConfirmed() {
  return usePostCallAssistTrackingEvent<void>(
    "Call Assist Activation Confirmed"
  )
}

export function usePostCallAssistToggled() {
  return usePostCallAssistTrackingEvent<{ new_toggle_status: boolean }>(
    "Call Assist Toggled"
  )
}

export function usePostCallAssistCancelInitiated() {
  return usePostCallAssistTrackingEvent("Call Assist Cancel Initiated")
}

export function usePostCallAssistCancelConfirmed() {
  return usePostCallAssistTrackingEvent("Call Assist Cancel Confirmed")
}
